import React from "react";
import { useSelector } from "react-redux";
import Faceswap from "../ImageProcessing/Faceswap";
import Apparels from "../ImageProcessing/ApparelSwap/ApparelSwap";
import Backgroundswap from "../ImageProcessing/Backgroundswap";
import ModelGeneration from "../ImageProcessing/ModelGeneration";
import GarmentAnalysis from "../ImageProcessing/DescriptionGenerator/DescriptionGen";
import { AIFeatureViewNames } from "../../config/constants";

const Studio = () => {
  const [activeTab, setActiveTab] = React.useState("FACE_SWAP");

  // Updated state selection
  const studioState = useSelector((state) => state.studio);

  const tabs = Object.entries(AIFeatureViewNames).map(([id, title]) => ({
    id,
    title,
  }));

  return (
    <div className="my-12 " id="bannerSection">
      <div className="mx-auto px-4">
        <div className=" rounded-lg p-6">
          {/* Tab buttons */}
          <div className="flex flex-wrap justify-center gap-4 mb-8">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`px-6 py-3 text-sm font-medium rounded-full transition-all duration-300 ease-in-out
                  ${
                    activeTab === tab.id
                      ? "bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg transform scale-105"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                  }`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.title}
              </button>
            ))}
          </div>
          {/* Tab content */}
          <div className="tab-content">
            {activeTab === "FACE_SWAP" && (
              <Faceswap state={studioState.FACE_SWAP} />
            )}
            {activeTab === "APPAREL_SWAP" && (
              <Apparels state={studioState.APPAREL_SWAP} />
            )}
            {activeTab === "BG_SWAP" && (
              <Backgroundswap state={studioState.BG_SWAP} />
            )}
            {(activeTab === "MODEL_GEN" || activeTab === "BG_GEN") && (
              <ModelGeneration
                state={studioState[activeTab]}
                activeTab={activeTab}
              />
            )}
            {activeTab === "DESC_GEN" && (
              <GarmentAnalysis state={studioState.DESC_GEN} />
            )}
            {/* Add placeholders for other tabs */}
            {[
              "MODEL_SWAP",
              "POSTURE_CHANGE",
              "MQUINM",
              "CMQUIN",
              "CATALOGUER",
              "UPSCALER",
              "INPAINT",
              "OUTPAINT",
            ].includes(activeTab) && (
              <div>
                Placeholder for{" "}
                {tabs.find((tab) => tab.id === activeTab)?.title}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Studio;
