import React, { useState } from "react";
import {
  Images,
  Upload,
  UserSearchIcon,
  ChevronLeft,
  Camera,
  ChevronRight,
} from "lucide-react";
import { DownloadButton } from "../../Utils/DownloadButton.jsx";
import MaskingTool from "./MaskingTool";

const ReusableProcessor = ({
  title,
  images,
  uploadTitle,
  onImageSelect,
  onImageUpload,
  onRun,
  selectedImage,
  selectedFilter,
  appliedFilter,
  isRunning,
  isDownloadReady,
}) => {
  const [showAllImages, setShowAllImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [UploadedImage, setUploadedImage] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImages = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex + 3 >= images.length ? 0 : prevIndex + 3
    );
  };

  const prevImages = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex - 3 < 0 ? Math.max(images.length - 3, 0) : prevIndex - 3
    );
  };

  const handleFilterSelect = (filter) => {
    onImageSelect(filter);
    setUploadedImage({
      newImage: filter,
      newImageUrl: filter,
    });
    setShowAllImages(false);
  };

  const handleShowMore = () => {
    setShowAllImages(true);
  };

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setUploadedImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onImageSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left side - Filter Images */}
      <div className="w-full lg:w-[40%] p-6 rounded-xl h-max border border-[#ee0d0d] mt-4">
        <h2 className="text-2xl font-bold mb-4">{title}</h2>

        <div className="aspect-square w-fit mx-auto mb-4 relative">
          {UploadedImage.newImageUrl ? (
            //masking tool added
            <MaskingTool imageUrl={UploadedImage.newImageUrl} width={300} />
          ) : (
            <Camera className="w-24 h-24 text-purple-700 mx-auto mb-2" />
          )}
        </div>

        <div className="w-full flex items-center justify-center mt-4">
          <label className="relative inline-block">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e)}
              className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
            />
            <span
              className="bg-white text-black border py-2 px-4 rounded-md cursor-pointer hover:bg-purple-700 transition-colors flex items-center justify-center"
              style={{ borderColor: "purple" }}
            >
              <Upload className="mr-4" />
              <span className="text-sm">Upload Image</span>
            </span>
          </label>
        </div>

        <div className="flex w-full justify-center items-center ">
          <div className="border w-full"></div>
          <p className="mt-3 mx-4">OR</p>
          <div className="border w-full"></div>
        </div>
        <div className="flex mb-4 items-center justify-between">
          <button
            onClick={prevImages}
            className="p-2 bg-purple-100 rounded-full hover:bg-purple-200 transition-colors duration-300"
          >
            <ChevronLeft size={24} className="text-purple-600" />
          </button>
          <div className="flex mb-4 items-center overflow-hidden">
            <div className="flex w-72 items-center gap-2">
              {images
                .slice(currentImageIndex, currentImageIndex + 3)
                .map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                      selectedFilter === img
                        ? "ring-4 ring-purple-600 scale-105"
                        : "ring-1 ring-gray-300 hover:ring-purple-400 hover:scale-105"
                    }`}
                    alt={`Filter ${currentImageIndex + index + 1}`}
                    onClick={() => handleFilterSelect(img)}
                  />
                ))}
            </div>
          </div>
          <button
            onClick={nextImages}
            className="p-2 bg-purple-100 rounded-full hover:bg-purple-200 transition-colors duration-300"
          >
            <ChevronRight size={24} className="text-purple-600" />
          </button>
        </div>

        <button
          className="w-full py-2 bg-purple-600 text-white text-sm rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center"
          onClick={handleShowMore}
        >
          <UserSearchIcon className="mr-4" />
          <span className="text-sm">View All Image</span>
        </button>
      </div>

      <div className="w-full lg:w-3/4 xl:pl-5 lg:pt-6 ">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-[45%] h-fit bg-white rounded-lg border border-[#ee0d0d] p-4">
            <h2 className="text-2xl font-bold mb-4">Upload a {uploadTitle}</h2>
            <div className="aspect-square w-fit mx-auto mb-4">
              {selectedImage.blobImageUrl ? (
                <img
                  src={selectedImage.blobImageUrl}
                  alt="preview"
                  className={`w-[300px] h-full object-contain rounded-lg ${
                    selectedFilter === selectedImage.newImageUrl
                      ? "ring-4 ring-purple-600"
                      : ""
                  }`}
                />
              ) : (
                <Images className="w-[200px] h-[300px] text-purple-700" />
              )}
            </div>
            <div className="w-full flex items-center justify-center mt-4">
              <label className="relative inline-block">
                <input
                  type="file"
                  accept="image/*"
                  onChange={onImageUpload}
                  className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
                />
                <span
                  className="bg-white text-black border py-2 px-4 rounded-md cursor-pointer hover:bg-purple-700 transition-colors flex items-center justify-center"
                  style={{ borderColor: "purple" }}
                >
                  <Upload className="mr-4" />
                  <span className="text-sm">Upload Image</span>
                </span>
              </label>
            </div>
          </div>

          {/* Processed Image Section */}
          <div className="w-full md:w-[45%] bg-white rounded-lg border p-4">
            <h2 className="text-2xl font-bold mb-4">Output</h2>
            <div className="aspect-square w-fit mx-auto mb-4">
              {isRunning ? (
                <div className="w-[300px] h-[300px] bg-gray-200 rounded-lg flex items-center justify-center">
                  <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
                </div>
              ) : appliedFilter ? (
                <img
                  src={appliedFilter}
                  alt="filtered"
                  className="w-[300px] h-full object-contain rounded-lg cursor-pointer"
                  onClick={() => openFullscreenImage(appliedFilter)}
                />
              ) : (
                <div className="w-[300px] h-[300px] bg-gradient-to-br from-purple-100 to-gray-200 rounded-lg flex items-center justify-center overflow-hidden group transition-all duration-300 ease-in-out hover:shadow-lg hover:scale-105">
                  <div className="text-center p-6 transform transition-transform duration-300 group-hover:scale-110">
                    <svg
                      className="w-16 h-16 mx-auto mb-4 text-purple-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    <p className="text-purple-700 font-medium group-hover:text-purple-900 transition-colors duration-300">
                      Ready to see the magic?
                    </p>
                    <p className="text-gray-500 mt-2 group-hover:text-gray-700 transition-colors duration-300">
                      Upload an image and hit 'Run'!
                    </p>
                  </div>
                </div>
              )}
            </div>
            {isDownloadReady && <DownloadButton imageUrl={appliedFilter} />}
          </div>
        </div>

        {/* Run Button */}
        <div className="mt-3">
          <button
            className={`w-fit px-6 py-3 rounded-lg flex items-center justify-center text-lg font-semibold ${
              selectedFilter && selectedImage.blobImage
                ? "bg-purple-600 text-white hover:bg-purple-700"
                : "bg-gray-300 text-gray-600 cursor-not-allowed"
            } transition-colors`}
            onClick={onRun}
            disabled={!selectedFilter || !selectedImage.blobImage || isRunning}
          >
            {isRunning ? "Processing..." : "Run"}
          </button>
        </div>
      </div>

      {/* Fullscreen Image Modal */}
      {fullscreenImage && (
        <div
          onClick={closeFullscreenImage}
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
        >
          <div className="relative w-full h-full max-w-4xl max-h-4xl">
            <img
              src={fullscreenImage}
              alt="fullscreen"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      )}

      {/* Pop-up for all images */}
      {showAllImages && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
            <button
              className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
              onClick={() => setShowAllImages(false)}
            >
              ×
            </button>
            <h3 className="text-2xl font-bold mb-6">Choose an Image</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                    selectedFilter === img
                      ? "ring-4 ring-purple-600"
                      : "ring-1 ring-gray-300 hover:ring-purple-400"
                  }`}
                  alt={`Filter ${index + 1}`}
                  onClick={() => handleFilterSelect(img)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReusableProcessor;
